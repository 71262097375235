<template>
  <div class="nailingHtml">
    <div class="taskCard" v-if="ids">
      <div class="top">详情</div>
      <div class="content">
        <div class="title">任务标题：{{ content.taskTitle }}</div>
        <div class="person">
          {{
            content.userRoleType == 0
              ? "项目经理"
              : content.userRoleType == 1
              ? "分配人"
              : content.userRoleType == 2
              ? "进度跟踪"
              : content.userRoleType == 3
              ? "现场支持"
              : ""
          }}：{{ content.dutyPerson }}
        </div>
        <div class="startTime">任务开始时间：{{ content.startDate }}</div>
        <div class="endTime">任务截至时间：{{ content.dueDate }}</div>
        <div class="priority">优先级：{{ content.priority }}</div>
        <div class="creationDate">创建时间：{{ content.createDate }}</div>
      </div>
      <div class="button" v-if="!content.status">
        <van-button class="button" type="info" @click="buttonOnClick(3)"
          >拒绝</van-button
        >
        <van-button
          class="button"
          type="info"
          @click="buttonOnClick(2)"
          v-if="content.userRoleType == 0"
          >领取</van-button
        >
        <van-button class="button" type="info" @click="buttonOnClick(2)" v-else
          >接收</van-button
        >
      </div>
      <div class="status" v-else>
        状态:
        <div>{{ content.status }}</div>
      </div>
    </div>
    <van-overlay :show="show">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </van-overlay>
  </div>
</template>

<script>
import { Button, dialog, loading } from "@/utils/vant";
import { queryDingDingNoticeStatus, DingDingbuttonOnClick } from "@/api/home";
export default {
  data() {
    return {
      ids: this.$route.query.ids, // 请求参数
      show: false, //加载显示
      content: {
        createDate: "",
        dueDate: "",
        dutyPerson: "",
        priority: "",
        startDate: "",
        status: "",
        taskTitle: "",
        status: "",
        userRoleType: 0,
      }, // 显示内容
    };
  },
  mounted() {
    if (this.ids) {
      this.queryDingDingNoticeStatus();
    } else {
      this.$dialog.alert({
        title: "提示",
        message: "链接有误,请重新跳转",
      });
      return false;
    }
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#f9f9f9");
  },
  methods: {
    // 查询钉钉通知是否已经领取或接收
    queryDingDingNoticeStatus() {
      this.show = true;
      queryDingDingNoticeStatus({ ids: this.ids }).then((res) => {
        this.show = false;
        if (res.success) {
          this.content = res.data;
        } else {
          this.$dialog.alert({
            title: "提示",
            message: `${res.message}`,
          });
        }
      });
    },
    // 钉钉点击按钮事件
    buttonOnClick(type) {
      this.DingDingbuttonOnClick(type);
    },
    // 钉钉点击按钮事件接口调用
    DingDingbuttonOnClick(type) {
      let newIds = `${this.ids}_${type}`;
      this.show = true;
      DingDingbuttonOnClick({ ids: newIds }).then((res) => {
        this.show = false;
        if (res.success) {
          this.queryDingDingNoticeStatus();
        } else {
          this.$dialog.alert({
            title: "提示",
            message: `${res.message}`,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.taskCard {
  width: 90%;
  height: 450px;
  margin: 50px auto;
  border-radius: 10px;
  background: #fff;

  .top {
    height: 60px;
    line-height: 60px;
    text-indent: 40px;
    font-size: 32px;
    font-weight: 700;
    color: #000;
    text-align: left;
    border-bottom: 2px solid #f6f6f6;
  }

  .content {
    margin-top: 40px;
    padding-left: 40px;
    font-size: 28px;
    text-align: left;
    color: #b8bcc0;

    div {
      line-height: 40px;
    }
  }

  .status {
    margin-top: 20px;
  }

  .button {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;

    /deep/.van-button--normal {
      width: 140px;
      height: 60px;
      border-radius: 8px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      font-size: 28px;
    }
  }

  .status {
    padding-left: 40px;
    color: #b8bcc0;
    font-size: 28px;
    text-align: left;

    div {
      display: inline-block;
      margin-left: 40px;
      color: #2b9cea;
    }
  }
}
/deep/.van-loading {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
